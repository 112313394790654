<template>
    <div class="container mb-5 mt-3">
        <div class="order-success px-3 py-5">
            <h1 class="text-center text-success">
                <i class="bi bi-check-circle-fill"></i> Done
            </h1>
            <br>
            <h5 class="text-center mb-3">
                আপনার অর্ডার সফলভাবে নিশ্চিত করা হয়েছে।
            </h5>
            <h5 class="text-center">
                Hatibari.com হাতিবাড়ি.কম এর সাথে থাকার জন্য ধন্যবাদ।
            </h5>
            <br>
            <div class="text-center pt-4">
                <router-link to="/" class="btn btn-primary btn-lg rounded-0">কেনাকাটা চালিয়ে যান</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue'

export default {
    setup() {

        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        })

        return {
            
        }
    }
};
</script>

<style lang="scss" scoped>
.order-success {
    .bi-check-circle-fill {
        font-size: 38px;
    }
}
</style>