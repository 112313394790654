import { auth } from "@/firebase"
import { onAuthStateChanged, signOut, signInWithEmailAndPassword } from "firebase/auth";
import ruter from "@/router";

export default ({
    namespaced: true,
    state: {
        userId: ''
    },
    getters: {
        getUserId(state) {
            return state.userId;
        }
    },
    mutations: {
        updateUserStatus(state, status) {
            state.userId = status;
        },
    },
    actions: {
        checkAuthentication({ commit }) {
            return new Promise((resolve, reject)=>{
                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        commit('updateUserStatus', user.uid);
                        resolve(true)
                        ruter.push({path: '/dashboard'});
                    } else {
                        commit('updateUserStatus', '');
                        ruter.push({path: '/login'});
                        reject(false)
                    }
                });
            })
        },
        signIn({ commit }, userInfo) {
            return new Promise((resolve, reject)=>{
                signInWithEmailAndPassword(auth, userInfo.email, userInfo.password)
                .then((response) => {
                    commit('updateUserStatus', response.user.uid);
                    ruter.push({path: '/dashboard'});
                    resolve(response)
                })
                .catch((error) => {
                    commit('updateUserStatus', '');
                    reject(error)
                });
            })
        },
        signOut({ commit }) {
            return new Promise((resolve, reject)=>{
                signOut(auth)
                .then((response) => {
                    commit('updateUserStatus', '');
                    ruter.push({path: '/login'});
                    resolve(false)
                })
                .catch((error) => {
                    reject(true)
                });
            })
        }
    }
})
