import { db } from "@/firebase"
import { query, orderBy, startAt, startAfter, collection, getDocs, getDoc, addDoc, setDoc, doc, limit, onSnapshot, updateDoc, deleteDoc, getCountFromServer, where } from "firebase/firestore";
import { toast } from 'vue3-toastify';

export default ({
    namespaced: true,
    state: {
        
    },
    getters: {
        
    },
    mutations: {
        
    },
    actions: {
        saveOrder({ commit }, info) {
            return new Promise((resolve, reject) => {
                getDoc(doc(db, "listings", info.item.docId))
                    .then((listingdoc) => {
                        if (listingdoc.exists()) {
                            if ((info.info.quantity <= listingdoc.data().quantity) && (listingdoc.data().quantity > 0)) {
                                addDoc(collection(db, "orders"), info.info)
                                .then((success) => {
                                    localStorage.setItem("orderId", success.id)
                                    localStorage.setItem("shippingCost", info.info.orderAsAGift ? (info.info.totalPrice + info.info.shippingCost) : info.info.shippingCost)
                                    updateDoc(doc(db, "listings", info.item.docId), {
                                        quantity: listingdoc.data().quantity - info.info.quantity
                                    });
                                    resolve(true)
                                })
                                .catch((error) => {
                                    toast("কিছু ভুল হয়েছে আবার চেষ্টা করুন।", {
                                        "theme": "colored",
                                        "type": "error",
                                        "autoClose": 3000,
                                        "transition": "flip",
                                        "dangerouslyHTMLString": true
                                    })
                                    reject(false)
                                })
                            } else {
                                toast("স্টকে পর্যাপ্ত পণ্য নেই। স্টকে পণ্য আছে "+listingdoc.data().quantity+" টি।", {
                                    "theme": "colored",
                                    "type": "error",
                                    "autoClose": 3000,
                                    "transition": "flip",
                                    "dangerouslyHTMLString": true
                                })
                                reject(false)
                            }
                        } else {
                            toast("পণ্যটি পাওয়া যায়নি।", {
                                "theme": "colored",
                                "type": "error",
                                "autoClose": 3000,
                                "transition": "flip",
                                "dangerouslyHTMLString": true
                            })
                            reject(false)
                        }
                    })
                    .catch((error) => {
                        reject(false)
                    })
            })
        },
        confirmOrder({ commit }, info) {
            return new Promise((resolve, reject) => {
                const washingtonRef = doc(db, "orders", info.orderId)
                updateDoc(washingtonRef, {
                    transectionId: info.transectionId
                })
                .then((success) => {
                    resolve(true)
                    localStorage.removeItem("orderId")
                    localStorage.removeItem("shippingCost")
                })
                .catch((error) => {
                    reject(false)
                })
            })
        },
    }
})
