<template>
    <ListingDetails v-if="productType == 1" />
    <LandingItemDetails v-if="productType == 2" />
</template>

<script>
import { computed, ref, onMounted, onUpdated } from 'vue'
import { useRoute } from 'vue-router'
import ListingDetails from "@/components/listing/ListingDetails.vue";
import LandingItemDetails from "@/components/listing/LandingItemDetails.vue";

export default {
    components: {
        ListingDetails,
        LandingItemDetails
    },
    setup() {
        const theRoute = useRoute()
        const listingId = ref("")
        const productType = ref(0)

        onMounted(() => {
            listingId.value = theRoute.params.id
            productType.value = parseInt(theRoute.query.t)
        })

        return {
            listingId,
            productType,
        }
    }
};
</script>
