<template>
    <nav class="navbar navbar-expand-lg sticky-top py-1 border-bottom">
        <div class="container py-1">
            <router-link class="navbar-brand pt-1" to="/"><img src="../../assets/logo.png" height="48" alt="Logo" /></router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
                    </li>
                </ul> -->

                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <router-link class="nav-link active" aria-current="page" to="/">হোম</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link active" aria-current="page" to="/khejur-gur">খেজুর গুড়</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link active" aria-current="page" to="/saree">শাড়ি</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link active" aria-current="page" to="/about">আমাদের সম্পর্কে</router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link class="nav-link active" aria-current="page" to="/contact">Contact</router-link>
                    </li> -->
                </ul>
                <!-- <form class="d-flex" role="search">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success" type="submit">Search</button>
                </form> -->
            </div>
        </div>
    </nav>
</template>

<script>
// import { computed, ref, onMounted } from 'vue'

export default {
    setup() {

        return {
            
        }
    }
};
</script>

<style lang="scss" scope>
@import "src/_preset.scss";

.navbar {
    background-color: $cs-white !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .navbar-toggler {
        background-color: $cs-white !important;
    }

    a.router-link-active.router-link-exact-active.nav-link.active {
        color: #179BE8 !important;
    }

    .px-2 {
        padding-right: 0.8rem !important;
        padding-left: 0.8rem !important;
    }
}
</style>