<template>
    <div id="page-container">
        <div id="content-wrap">
            <Header></Header>
            <router-view />
        </div>
        <div id="footer">
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import Header from '@/components/includes/Header.vue'
import Footer from '@/components/includes/Footer.vue'
import { onMounted } from "vue"
import { useStore } from 'vuex';

export default {
    components: {
        Header,
        Footer,
    },
    setup() {
        const store = useStore();

        onMounted(() => {
            // checkUserAuthentication();

            store.dispatch('listings/getLandingItemList', {limit: 5})
            store.dispatch('listings/getListingItemList', {limit: 5})

            store.dispatch('listings/getCategoryList')
            store.dispatch('listings/getSubCategoryList')
            store.dispatch('listings/getGroupList')
            store.dispatch('listings/getBrandList')
            store.dispatch('listings/getColorList')
            store.dispatch('listings/getSizeList')
        })

        function checkUserAuthentication() {
            store.dispatch('auth/checkAuthentication')
                .then((response) => {

                })
                .catch((error) => {

                })
        }

        return { checkUserAuthentication };
    }
};
</script>

<style lang="scss">
@import "src/_preset.scss";

body {
    background-color: #eff7fa !important;
}

* {
    font-family: "Graphik Webfont", "-apple-system", "Helvetica Neue", "Droid Sans", "Arial", "sans-serif";
    font-size: 16px;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 3.5rem;    /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;            /* Footer height */
}

.btn-primary {
    background-color: $cs-primary !important;
    border: 1px solid $cs-primary !important;
    color: $cs-white !important;
    transition: transform .2s;
}
.btn-primary:hover {
    transform: scale(1.02);
}

.btn-secondary {
    background-color: $cs-secondary !important;
    border: 1px solid $cs-secondary !important;
    color: $cs-white !important;
    transition: transform .2s;
}

.btn-secondary:hover {
    transform: scale(1.02);
}
</style>
