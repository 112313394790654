import { db } from "@/firebase"
import { query, orderBy, startAt, startAfter, collection, getDocs, getDoc, addDoc, setDoc, doc, limit, onSnapshot, updateDoc, deleteDoc, getCountFromServer, where } from "firebase/firestore";
import { toast } from 'vue3-toastify';

export default ({
    namespaced: true,
    state: {
        listingItemList: [],
        landingItemList: [],
        categoryList: [],
        subCategoryList: [],
        groupList: [],
        colorList: [],
        sizeList: [],
        brandList: [],
    },
    getters: {
        getLandingItemList(state) {
            return state.landingItemList;
        },
        getListingItemList(state) {
            return state.listingItemList;
        },
        getCategoryList(state) {
            return state.categoryList;
        },
        getSubCategoryList(state) {
            return state.subCategoryList;
        },
        getGroupList(state) {
            return state.groupList;
        },
        getColorList(state) {
            return state.colorList;
        },
        getSizeList(state) {
            return state.sizeList;
        },
        getBrandList(state) {
            return state.brandList;
        },
    },
    mutations: {
        setCategoryList(state, list) {
            state.categoryList = list;
        },
        setSubCategoryList(state, list) {
            state.subCategoryList = list;
        },
        setGroupList(state, list) {
            state.groupList = list;
        },
        setColorList(state, list) {
            state.colorList = list;
        },
        setSizeList(state, list) {
            state.sizeList = list;
        },
        setBrandList(state, list) {
            state.brandList = list;
        },
    },
    actions: {
        // listing actions
        getLandingItemList({ commit, state }, filter) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "listings");
                const q = query(citiesRef, orderBy("title"), where("listingStatus", "==", 2), where("listingType", "==", 2), limit(filter.limit));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            title: doc.data().title,
                            description: doc.data().description,
                            sections: doc.data().sections,
                            tags: doc.data().tags,
                            price: doc.data().price,
                            discount: doc.data().discount,
                            quantity: doc.data().quantity,
                            photos: doc.data().photos,
                            video: doc.data().video,
                            category: doc.data().category,
                            subCategory: doc.data().subCategory,
                            group: doc.data().group,
                            brand: doc.data().brand,
                            colors: doc.data().colors,
                            sizes: doc.data().sizes,
                            materials: doc.data().materials,
                            inDhaka: doc.data().inDhaka,
                            outOfDhaka: doc.data().outOfDhaka,
                            additionalMessage: doc.data().additionalMessage,
                            listingStatus: doc.data().listingStatus,
                            listingType: doc.data().listingType,
                            createDate: doc.data().createDate,
                            updateDate: doc.data().updateDate,
                            state: false
                        })
                    });
                    state.landingItemList = list
                });
            })
        },
        getListingItemList({ commit, state }, filter) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "listings");
                const q = query(citiesRef, orderBy("title"), where("listingStatus", "==", 2), where("listingType", "==", 1), limit(filter.limit));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            title: doc.data().title,
                            description: doc.data().description,
                            sections: doc.data().sections,
                            tags: doc.data().tags,
                            price: doc.data().price,
                            discount: doc.data().discount,
                            quantity: doc.data().quantity,
                            photos: doc.data().photos,
                            video: doc.data().video,
                            category: doc.data().category,
                            subCategory: doc.data().subCategory,
                            group: doc.data().group,
                            brand: doc.data().brand,
                            colors: doc.data().colors,
                            sizes: doc.data().sizes,
                            materials: doc.data().materials,
                            inDhaka: doc.data().inDhaka,
                            outOfDhaka: doc.data().outOfDhaka,
                            additionalMessage: doc.data().additionalMessage,
                            listingStatus: doc.data().listingStatus,
                            listingType: doc.data().listingType,
                            createDate: doc.data().createDate,
                            updateDate: doc.data().updateDate,
                            state: false
                        })
                    });
                    state.listingItemList = list;
                });
            })
        },
        getItemDetailsByDocId({ commit, state }, docId) {
            return new Promise((resolve, reject) => {
                let list = []
                const docRef = doc(db, "listings", docId)

                getDoc(docRef)
                    .then((doc) => {
                        if (doc.exists()) {
                            list.push({
                                docId: doc.id,
                                title: doc.data().title,
                                description: doc.data().description,
                                sections: doc.data().sections,
                                tags: doc.data().tags,
                                price: doc.data().price,
                                discount: doc.data().discount,
                                quantity: doc.data().quantity,
                                photos: doc.data().photos,
                                video: doc.data().video,
                                category: doc.data().category,
                                subCategory: doc.data().subCategory,
                                group: doc.data().group,
                                brand: doc.data().brand,
                                colors: doc.data().colors,
                                sizes: doc.data().sizes,
                                materials: doc.data().materials,
                                inDhaka: doc.data().inDhaka,
                                outOfDhaka: doc.data().outOfDhaka,
                                additionalMessage: doc.data().additionalMessage,
                                listingStatus: doc.data().listingStatus,
                                listingType: doc.data().listingType,
                                createDate: doc.data().createDate,
                                updateDate: doc.data().updateDate,
                                state: false
                            })
                            resolve(list)
                        } else {
                            resolve([])
                        }
                    })
                    .catch((error) => {
                        reject([])
                    });
            })
        },
        getProductListByCategory({ commit, state }, filter) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "listings");
                const q = query(citiesRef, orderBy("title"), where("listingStatus", "==", 2), where("subCategory", "==", filter.subCategoryId));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            title: doc.data().title,
                            description: doc.data().description,
                            sections: doc.data().sections,
                            tags: doc.data().tags,
                            price: doc.data().price,
                            discount: doc.data().discount,
                            quantity: doc.data().quantity,
                            photos: doc.data().photos,
                            video: doc.data().video,
                            category: doc.data().category,
                            subCategory: doc.data().subCategory,
                            group: doc.data().group,
                            brand: doc.data().brand,
                            colors: doc.data().colors,
                            sizes: doc.data().sizes,
                            materials: doc.data().materials,
                            inDhaka: doc.data().inDhaka,
                            outOfDhaka: doc.data().outOfDhaka,
                            additionalMessage: doc.data().additionalMessage,
                            listingStatus: doc.data().listingStatus,
                            listingType: doc.data().listingType,
                            createDate: doc.data().createDate,
                            updateDate: doc.data().updateDate,
                            state: false
                        })
                    });
                    resolve(list)
                });
            })
        },
        // category actions
        getCategoryList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "categories");
                const q = query(citiesRef, orderBy("category"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            category: doc.data().category,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setCategoryList", list);
                });
            })
        },
        // sub category actions
        getSubCategoryList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "subcategories");
                const q = query(citiesRef, orderBy("subCategory"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            categoryId: doc.data().categoryId,
                            subCategory: doc.data().subCategory,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setSubCategoryList", list);
                });
            })
        },
        // groups actions
        getGroupList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "groups");
                const q = query(citiesRef, orderBy("group"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            categoryId: doc.data().categoryId,
                            subCategoryId: doc.data().subCategoryId,
                            group: doc.data().group,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setGroupList", list);
                });
            })
        },
        // brand actions
        getBrandList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "brands");
                const q = query(citiesRef, orderBy("brand"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            brand: doc.data().brand,
                            state: false,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setBrandList", list);
                });
            })
        },
        // color actions
        getColorList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "colors");
                const q = query(citiesRef, orderBy("color"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            color: doc.data().color,
                            colorCode: doc.data().colorCode,
                            state: false,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setColorList", list);
                });
            })
        },
        // size actions
        getSizeList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "sizes");
                const q = query(citiesRef, orderBy("size"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            size: doc.data().size,
                            state: false,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setSizeList", list);
                });
            })
        },
    }
})
