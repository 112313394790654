<template>
    <div class="container my-5 pt-2">
        <div class="order-success p-4">
            <h5 class="title">আপনার অর্ডার সফলভাবে গ্রহণ করা হয়েছে।</h5>
            <div class="alert alert-primary mt-4">
                <h6 class="m-0 mb-2">আপনার অর্ডার নিশ্চিত করতে ৳{{ shippingCost }} পাঠান।</h6>
                <h6 class="m-0">বিকাশ/নগদ/রকেটে টাকা পাঠান: 01786744150 (Personal)</h6>
            </div>
            <br>
            <div class="mb-4">
                <label for="transection-id" class="form-label">আপনার বিকাশ/নগদ/রকেট নম্বরের শেষ 3 টি ডিজিট লিখুন</label>
                <input v-model="transectionId" type="text" maxlength="3" class="form-control form-control-lg rounded-0" id="transection-id" >
                <!-- <div v-if="isRequired" class="text-danger mt-1">Transaction id is required.</div> -->
            </div>

            <div class="text-end pt-3">
                <button @click="confirmOrder" type="button" class="btn btn-primary btn-lg rounded-0">অর্ডার নিশ্চিত করুন <span v-if="spinner" class="spinner"></span></button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';

export default {
    setup() {
        const orderId = ref('')
        const shippingCost = ref('')
        const transectionId = ref('')
        const store = useStore()
        const router = useRouter()
        const spinner = ref(false)
        
        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0
            orderId.value = localStorage.getItem("orderId")
            shippingCost.value = localStorage.getItem("shippingCost")
        })

        function confirmOrder() {
            spinner.value = true
            store.dispatch('orders/confirmOrder', {orderId: orderId.value, transectionId: transectionId.value})
            .then((res)=> {
                spinner.value = false
                router.push("/order-success")
            })
            .catch((err)=>{
                spinner.value = false
            })
        }

        return {
            spinner,
            orderId,
            shippingCost,
            transectionId,
            confirmOrder
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/_preset.scss";

.order-success {
    background-color: $cs-white;
    max-width: 600px;
    width: 100%;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .title {
        font-size: $cs-h5-font-size;
        color: $cs-secondary;
        line-height: 30px;
        font-weight: 600;
    }
    .alert {
        * {
            font-size: $cs-normal-font-size-2 !important;
            line-height: 30px;
        }
        span {
            font-size: $cs-h6-font-size !important;
            color: $cs-required !important;
        }
    }
    label {
        font-weight: 600;
    }
}
</style>