<template>
    <div v-if="listings.length>0" class="container mb-5 mt-3">
        <template v-for="(item, index) in listings" :key="index">
            <template v-if="listingId == item.docId">
                <!-- <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">{{item.title}}</li>
                    </ol>
                </nav> -->
                <div class="listing-details p-4 mt-4">
                    <div class="row">
                        <div class="col-md-4">
                            <div id="myViewport">
                                <img id="zoomImgeId" :src="primaryPhotoSource" :alt="primaryPhotoAltText" class="w-100">
                            </div>
                            <div class="more-product-images pt-2 pb-1 w-100">
                                <table>
                                    <tr>
                                        <td v-for="(photoItem, photoIndex) in item.photos" :key="photoIndex">
                                            <img @click="selectPhotos(photoItem, photoIndex)" v-if="photoItem.source!=''" :src="photoItem.source" :alt="photoItem.altText" width="80" :class="{'ms-2': photoIndex>0, 'outline': selectedImageIndex==photoIndex}">
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div v-if="item.video" class="d-grid mt-3">
                                <a :href="item.video" target="_blank" class="btn btn-secondary btn-lg rounded-0"><i class="bi bi-play-circle-fill video-link me-1"></i> Watch video</a>
                            </div>
                        </div>
                        <div class="col-md-8 basic-info pt-4 pt-md-0">
                            <h1 class="title">{{item.title}}</h1>
                            <h2 v-if="item.discount" class="price mt-3 mb-2">৳{{item.price - item.discount}} <span>৳{{item.price}}</span></h2>
                            <h2 v-else class="price mt-3 mb-2">৳{{item.price}}</h2>
                            <div v-if="item.quantity > 0">
                                <div class="row mb-4">
                                    <div class="col-sm-6 col-md-6 col-lg-4">
                                        <label for="quantity">Quantity</label>
                                        <select v-model="quantity" class="mt-2 form-select form-select-lg rounded-0" id="quantity">
                                            <option v-for="(quantityItem, quantityIndex) in item.quantity" :key="quantityIndex" :value="quantityItem">{{quantityItem}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-sm-6 col-md-6 col-lg-4">
                                        <div class="d-grid gap-2">
                                            <router-link :to="'/order/'+item.docId+'?q='+quantity" class="btn btn-primary btn-lg rounded-0">Order Now</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 v-else class="text-danger m-0 p-0">
                                স্টকে নেই
                            </h5>
                            <hr>
                            <div class="description mt-4" v-html="item.description"></div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
    <div v-else class="container mb-5 mt-5 text-center pt-5">
        <img class="loader-image" src="../../assets/loading.gif" alt="Loading...">
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, onMounted, onUpdated } from 'vue'
import { useRoute } from 'vue-router';
import WZoom from '@/assets/vanilla-js-wheel-zoom-master/wheel-zoom.min.js'

export default {
    setup() {
        const store = useStore()
        const theRoute = useRoute()
        const listingId = ref("")
        const quantity = ref(1)
        const primaryPhotoSource = ref('')
        const primaryPhotoAltText = ref('')
        const selectedImageIndex = ref(0)

        const listings = ref([])

        onUpdated(() => {
            // set zoom efect with thambnail image
            WZoom.create('#zoomImgeId', {
                maxScale: 3,
                minScale: 1,
                dragScrollable: true,
                smoothTime: .25,
                speed: 10,
                type: 'html',
            });
        })

        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0
            listingId.value = theRoute.params.id

            store.dispatch('listings/getItemDetailsByDocId', listingId.value)
            .then((res)=>{
                listings.value = res
                // set thambnail image
                for (let nestIndex = 0; nestIndex < res[0].photos.length; nestIndex++) {
                    const element = res[0].photos[nestIndex];
                    if (nestIndex == 0 && element.source != '') {
                        primaryPhotoSource.value = element.source
                        primaryPhotoAltText.value = element.altText
                    }
                }
            })
            .catch((err)=>{
                console.log(err, 'err')
            })
        })

        function selectPhotos(photo, photoIndex) {
            primaryPhotoSource.value = photo.source
            primaryPhotoAltText.value = photo.altText
            selectedImageIndex.value = photoIndex
        }

        return {
            listingId,
            listings,
            quantity,
            primaryPhotoSource,
            primaryPhotoAltText,
            selectedImageIndex,
            selectPhotos
        };
    }
};
</script>

<style lang="scss" scoped>
@import "src/_preset.scss";

#myViewport {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.loader-image {
    width: 60px;
}

.listing-details {
    background-color: $cs-white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .more-product-images {
        width: 100%;
        overflow-y: auto;
        img {
            cursor: pointer;
        }
        .outline {
            border: 2px solid $cs-primary;
        }
    }
    .video-link {
        font-size: 20px;
    }
    .basic-info {
        .title {
            font-size: $cs-h4-font-size;
            color: $cs-secondary;
        }
        .price {
            color: $cs-primary;
            font-size: $cs-h3-font-size;
            span {
                text-decoration: line-through;
                color: $cs-required;
            }
        }
    }
}
</style>