<template>
    <div class="container">
        <Banner />
        <Products />
    </div>
</template>

<script>
import Banner from "./Banner.vue"
import Products from "@/components/listing/Products.vue";

export default {
    components: {
        Banner,
        Products
    },
    setup() {
        return {
            
        };
    }
};
</script>
