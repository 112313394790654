import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDCmRXjRPZqZ35oHYcPRacqk8IkJldtdPw",
    authDomain: "hatibariadmin.firebaseapp.com",
    projectId: "hatibariadmin",
    storageBucket: "hatibariadmin.appspot.com",
    messagingSenderId: "661097618392",
    appId: "1:661097618392:web:c0272d4d30defd669f30ad",
    measurementId: "G-ZDFYDNB4D5"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);

export {
    db
}

export {
    auth
}