<template>
    <div class="container">
        <div class="py-5">
            <div v-if="productList.length > 0">
                <h1 class="m-0">শাড়ি</h1>                
                <div class="listing-view text-start mt-4">
                    <div v-for="(item, index) in productList" :key="index" class="listing-item p-2">
                        <router-link :to="'/'+convertToHyphenAndLowercase(item.title)+'/'+item.docId+'?t='+item.listingType" class="img-and-title">
                            <img :src="item.photos[0].source" :alt="item.photos[0].altText" class="w-100">
                            <div>
                                <h5 v-if="item.discount" class="price mt-3">৳{{item.price - item.discount}} <span>৳{{item.price}}</span></h5>
                                <h5 v-else class="price mt-3">৳{{item.price}}</h5>
                                <div class="mt-2 title">
                                    {{ item.title }}
                                </div>
                            </div>
                        </router-link>
                        <div v-if="item.quantity > 0" class="row gx-2 mt-2">
                            <div class="col pt-1">
                                <div class="d-grid gap-2">
                                    <router-link :to="'/'+convertToHyphenAndLowercase(item.title)+'/'+item.docId+'?t='+item.listingType" class="btn btn-secondary btn-lg rounded-0">বিস্তারিত</router-link>
                                </div>
                            </div>
                            <div class="col pt-1">
                                <div class="d-grid gap-2">
                                    <router-link :to="'/order/'+item.docId+'?q='+quantity" class="btn btn-primary btn-lg rounded-0">অর্ডার</router-link>
                                </div>
                            </div>
                        </div>
                        <div v-else class="d-grid mt-2 pt-1">
                            <button disabled type="button" class="btn btn-danger btn-lg rounded-0">স্টকে নেই</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="productList.length == 0" class="mt-4">
                <div class="datatable-content-loader listing-view">
                    <div class="datatable-content-item"></div>
                    <div class="datatable-content-item"></div>
                    <div class="datatable-content-item"></div>
                    <div class="datatable-content-item"></div>
                    <div class="datatable-content-item"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, onMounted, watch, onUpdated } from 'vue'
import ProductService from "@/services/ProductService.js"

export default {
    setup() {
        const store = useStore()
        const productService = new ProductService()

        const subCategoryId = ref("NmHCBKhhJi8a0hOoOyiB")
        const quantity = ref(1)
        const productList = ref([])

        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0

            store.dispatch('listings/getProductListByCategory', {subCategoryId: subCategoryId.value})
                .then((res) => {
                    productList.value = res
                })
                .catch((err) => {
                    // console.log(err, 'err')
                })
        })

        function convertToHyphenAndLowercase(str) {
            return productService.convertToHyphenAndLowercase(str)
        }

        return {
            productList,
            quantity,
            subCategoryId,
            convertToHyphenAndLowercase
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/_preset.scss";

h1 {
    font-size: $cs-h4-font-size;
}

.listing-view {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-auto-rows: 1fr;
    column-gap: 16px;
    row-gap: 30px;
    text-align: center;
    font-size: 1.5em;

    button {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .listing-item {
        background-color: $cs-white;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        .price {
            color: $cs-secondary !important;
            span {
                text-decoration: line-through;
                color: $cs-required !important;
                font-weight: normal !important;
                font-size: 13px !important;
            }
        }
    }

    .listing-item:hover {
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    }

    .img-and-title {
        text-decoration: none;
        color: $cs-black !important;
        .title {
            height: 48px;
            overflow-y: hidden;
        }
    }
    .img-and-title:hover .title {
        color: $cs-link !important;
    }
}
</style>