<template>
    <div v-if="listings.length > 0" class="container mb-5 mt-3">
        <template v-for="(item, index) in listings" :key="index">
            <template v-if="listingId == item.docId">

                <div class="listing-details p-4 mt-4">
                    <h1 class="title">{{ item.title }}</h1>
                    <br>
                    <div class="text-center mt-2" v-if="item.quantity > 0">
                        <router-link :to="'/order/' + item.docId + '?q=' + quantity" class="btn btn-primary order-button">আমি নিতে চাই 
                            <h2 v-if="item.discount" class="price text-center d-inline-block">
                                (৳{{ item.price - item.discount }})
                            </h2>
                            <h2 v-else class="price text-center d-inline-block">(৳{{ item.price }})</h2>
                        </router-link>
                    </div>
                    <h5 v-else class="text-danger m-0 p-0 text-center">
                        <button disabled class="btn btn-primary order-button">স্টক শেষ</button>
                    </h5>
                    <br><br>
                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-10">
                            <!-- Carousel -->
                            <div id="demo" class="carousel slide" data-bs-ride="carousel">

                                <!-- Indicators/dots -->
                                <div class="carousel-indicators">
                                    <template v-for="(photoItem, photoIndex) in item.photos" :key="photoIndex">
                                        <button v-if="photoItem.source != ''" type="button" data-bs-target="#demo" :data-bs-slide-to="''+photoIndex" :class="{active: photoIndex==0}"></button>
                                    </template>
                                </div>

                                <!-- The slideshow/carousel -->
                                <div class="carousel-inner">
                                    <template v-for="(photoItem, photoIndex) in item.photos" :key="photoIndex">
                                        <div v-if="photoItem.source != ''" class="carousel-item" :class="{active: photoIndex==0}">
                                            <img :src="photoItem.source" class="d-block w-100" :alt="photoItem.altText">
                                        </div>
                                    </template>
                                </div>

                                <!-- Left and right controls/icons -->
                                <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon"></span>
                                </button>
                                <button class="carousel-control-next" id="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                                    <span class="carousel-control-next-icon"></span>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>

                    <!-- <div v-if="item.video" class="d-grid mt-3">
                        <a :href="item.video" target="_blank" class="btn btn-secondary btn-lg rounded-0">
                            <i class="bi bi-play-circle-fill video-link me-1"></i> Watch video
                        </a>
                    </div> -->

                    <br><br>
                    <div class="description mt-4" v-html="item.description"></div>
                    <br><br>
                    <div class="text-center mt-2" v-if="item.quantity > 0">
                        <router-link :to="'/order/' + item.docId + '?q=' + quantity" class="btn btn-primary order-button rounded-0">আমি নিতে চাই 
                            <h2 v-if="item.discount" class="price text-center d-inline-block">
                                (৳{{ item.price - item.discount }})
                            </h2>
                            <h2 v-else class="price text-center d-inline-block">(৳{{ item.price }})</h2>
                        </router-link>
                    </div>
                    <h5 v-else class="text-danger m-0 p-0 text-center">
                        <button disabled class="btn btn-primary order-button">স্টক শেষ</button>
                    </h5>
                </div>
            </template>
        </template>
    </div>
    <div v-else class="container mb-5 mt-5 text-center pt-5">
        <img class="loader-image" src="../../assets/loading.gif" alt="Loading...">
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, onMounted, onUpdated } from 'vue'
import { useRoute } from 'vue-router';

export default {
    setup() {
        const store = useStore()
        const theRoute = useRoute()
        const listingId = ref("")
        const quantity = ref(1)

        const listings = ref([])

        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0
            listingId.value = theRoute.params.id

            setInterval(function () {
                let obj = document.getElementById("carousel-control-next")
                if (obj) {
                    obj.click()
                }
            }, 10000)

            store.dispatch('listings/getItemDetailsByDocId', listingId.value)
                .then((res) => {
                    listings.value = res
                })
                .catch((err) => {
                    console.log(err, 'err')
                })
        })

        return {
            listingId,
            listings,
            quantity,
        };
    }
};
</script>

<style lang="scss" scoped>
@import "src/_preset.scss";

.loader-image {
    width: 60px;
}

.listing-details {
    background-color: $cs-white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .video-link {
        font-size: 20px;
    }

    .title {
        font-size: $cs-h4-font-size;
        color: $cs-white;
        background-color: $cs-secondary;
        padding: 26px 25px 20px 25px;
        text-align: center;
        line-height: 35px;
        line-height: 48px;
    }

    // .price {
    //     // color: $cs-primary;
    //     // font-size: $cs-h2-font-size;
    //     // text-decoration: underline;

    //     span {
    //         text-decoration: line-through;
    //         color: $cs-required;
    //         font-size: 22px;
    //     }
    // }

    .order-button {
        font-size: $cs-h4-font-size;
        padding: 15px 30px 8px 30px;
        border-radius: 3px !important;
    }
}
</style>