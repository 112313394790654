<template>
    <div class="container d-flex align-items-center justify-content-center vh-100">
        <div class="card">
            <div class="card-body p-2">
                <div class="card-title text-center pb-3"><h4>Login to <img src="../../assets/logo.png" height="32" alt="Logo" /></h4></div>
                <form @submit.prevent="onSubmit">
                    <div class="form-group">
                        <label class="mb-2" for="email">Email address</label>
                        <input @click="errorMessage=''" type="email" class="form-control" id="email" v-model="email" required>
                    </div>
                    <br>
                    <div class="form-group">
                        <label class="mb-2" for="password">Password</label>
                        <input @click="errorMessage=''" type="password" class="form-control" id="password" v-model="password" required>
                    </div>
                    <div v-if="errorMessage" class="alert alert-danger mt-4">
                        {{ errorMessage }}
                    </div>
                    <div class="text-center mt-5 mb-2">
                        <button type="submit" class="btn btn-primary" >Submit <span v-if="spinner" class="spinner"></span></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    setup() {
        const email = ref('');
        const password = ref('');
        const spinner = ref(false)
        const errorMessage = ref('');

        const store = useStore();

        const onSubmit = () => {
            spinner.value = true;
            store.dispatch('auth/signIn', {email: email.value, password: password.value})
                .then((response) => {
                    spinner.value = false;
                })
                .catch((error) => {
                    spinner.value = false;
                    if (error.code == 'auth/invalid-login-credentials') {
                        errorMessage.value = "Email or password is wrong.";
                    } else {
                        errorMessage.value = "Something went wrong, please try again later.";
                    }
                });
        };

        return { onSubmit, email, password, spinner, errorMessage };
    }
};
</script>

<style scoped>
.card {
    max-width: 360px;
    width: 100%;
    padding: 1em;
    margin: auto;
}
</style>