import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/auth/Login.vue'
import Home from '@/components/home/Home.vue'
import Order from "@/components/order/Order.vue";
import OrderSuccess from '@/components/order/OrderSuccess.vue';
import About from '@/components/home/About.vue';
import OrderConfirm from '@/components/order/OrderConfirm.vue';
import ProductDetails from '@/components/listing/ProductDetails.vue';
import Gur from '@/components/listing/Gur.vue';
import Saree from '@/components/listing/Saree.vue';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/:title/:id',
        name: 'Product details',
        component: ProductDetails
    },
    {
        path: '/order/:id',
        name: 'Order',
        component: Order
    },
    {
        path: '/order-confirm',
        name: 'Order confirm',
        component: OrderConfirm
    },
    {
        path: '/order-success',
        name: 'Order success',
        component: OrderSuccess
    },
    {
        path: '/khejur-gur',
        name: 'Khejur gur',
        component: Gur
    },
    {
        path: '/saree',
        name: 'Saree',
        component: Saree
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
